<template>
    <component :is="name" :loading="loading" @click="btnSubmit">
        {{ text }}
    </component>
</template>

<script>
import { ref } from "vue";
export default {
    name: "MainCell",
    props: {
        name: { tupe: String, default: "van-button" },
        text: String,
        submit: { type: Function, required: true },
    },
    setup(props) {
        let loading = ref(false);

        async function btnSubmit() {
            loading.value = true;
            props.submit().finally(() => {
                loading.value = false;
            });
        }

        return { loading, btnSubmit };
    },
};
</script>
