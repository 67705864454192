<template>
    <div class="container">
        <div>
            <van-cell-group class="content">
                <van-cell title="头像" center>
                    <van-image
                        :src="content.headIcon"
                        width="40"
                        height="40"
                        radius="5"
                    />
                </van-cell>
            </van-cell-group>
            <van-cell-group class="content">
                <van-cell title="手机号" :value="content.mobile" />
                <van-cell title="昵称" :value="content.name" />
            </van-cell-group>
        </div>
        <loading-button class="default-btn" text="退出登录" :submit="logout" />
    </div>
</template>

<script>
import { ref } from "vue";
import { logout, mine } from "@/api";
import LoadingButton from "@/components/LoadingButton";

export default {
    name: "Setting",
    components: { LoadingButton },
    setup() {
        let content = ref({});

        // 获取用户信息
        mine().then(({ data }) => (content.value = data));

        return { content, logout };
    },
};
</script>

<style lang="less" scoped>
@import url("@/styles/mixin.less");
.container {
    .flex(space-between, stretch, column);
    flex: 1;
    background-color: #f2f2f2;
    .content {
        margin-top: 10px;
    }
}
</style>
